<template>
  <div id="giftProductModal" class="create-post" uk-modal>
    <div
      class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small"
    >
      <div class="text-center py-3 border-b">
        <h3 class="text-lg font-semibold">Chi tiết quà tặng</h3>
        <button
          class="receivedGiftClose uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2"
          type="button"
          uk-close
          uk-tooltip="title: Close ; pos: bottom ;offset:7"
        ></button>
      </div>

      <div data-simplebar style="height: 450px">
        <div class="px-5 py-2">
          <div v-if="gift && gift.product && gift.product.images">
            <img
              v-for="(image,index) in gift.product.images"
              :key="'image' + index"
              :src="image.image"
              alt=""
            />
          </div>
          <div
            class="mt-3"
            v-html="gift && gift.product ? gift.product.content : ''"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GiftProductModal",
  props: {
    gift: Object
  },
};
</script>
