<template>
  <div id="receivingGiftModal" class="create-post" uk-modal>
    <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

      <div class="text-center py-3 border-b">
        <h3 class="text-lg font-semibold">{{ giftInfo ? giftInfo.post_title : '' }}</h3>
        <button class="receivingGiftClose uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2"
                type="button" uk-close
                uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
      </div>

      <div data-simplebar style="height: 450px">
        <div class="px-5 py-2">
          <p>Sản phẩm: {{ giftInfo && giftInfo.product ? giftInfo.product.post_title : '' }}</p>
          <div v-if="giftInfo && giftInfo.product && giftInfo.product.attributes && giftInfo.product.attributes.length">
            <p>Vui lòng chọn các thông tin sau:</p>
            <div class="mt-3" v-for="attribute in giftInfo.product.attributes" :key="'attribute' + gift.ID + attribute.name">
              <h3 class="font-semibold">{{ attribute.label }}</h3>
              <div class="flex" v-if="attribute.values && attribute.values.length">
                <span class="mr-5 flex inline-flex align-items-center" v-for="value in attribute.values" :key="'value' + gift.ID + attribute.name + value.name">
                  <input :id="'value' + gift.ID + attribute.name + value.name" type="radio" class="mr-2" v-bind:value="value.name" v-model="attribute.value"/><label style="margin-bottom: 0;" :for="'value' + gift.ID + attribute.name + value.name">{{ value.label }}</label>
                </span>
              </div>
            </div>
            <div class="mt-3" v-html="giftInfo.product ? giftInfo.product.guide : ''"></div>
            <div class="mt-3">
              <div class="mt-3">
                <label for="receiver" class="font-semibold">Người nhận</label>
                <input required v-model="giftInfo.receiver" class="shadow-none with-border" id="receiver" type="text" autocomplete="off">
              </div>
              <div class="mt-3">
                <label for="phone" class="font-semibold">SĐT</label>
                <input required v-model="giftInfo.phone" class="shadow-none with-border" id="phone" type="text" autocomplete="off">
              </div>
              <div class="mt-3">
                <label for="address" class="font-semibold">Địa chỉ</label>
                <input required v-model="giftInfo.address" class="shadow-none with-border" id="address" type="text" autocomplete="off">
              </div>
              <div class="mt-3">
                <label for="address" class="font-semibold">Ghi chú</label>
                <textarea class="shadow-none with-border" v-model="giftInfo.note" id="note"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center w-full justify-center border-t p-3">
        <a href="javascript:void(0)" v-on:click="receivingGift"
           class="bg-green-600 hover:bg-green-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
           {{ action }} thông tin</a>
      </div>
    </div>
  </div>
</template>

<script>

import ApiService from "../../core/services/api.service";

export default {
  name: "ReceivingGiftModal",
  props: {
    gift: Object,
  },
  data() {
    return {
      giftInfo: {
        attributes: "",
        receiver: "",
        phone: "",
        address: "",
      },
      action: 'Gửi'
    }
  },
  methods: {
    receivingGift() {
      if (!this.giftInfo.receiver) {
        this.$toast.error("Bạn chưa điền tên người nhận");
        return;
      }

      if (!this.giftInfo.phone) {
        this.$toast.error("Bạn chưa điền SĐT người nhận");
        return;
      }

      if (!this.giftInfo.address) {
        this.$toast.error("Bạn chưa điền địa chỉ người nhận");
        return;
      }

      let attributes = {};

      for (var i = 0; i < this.giftInfo.product.attributes.length; i++) {
        let name = this.giftInfo.product.attributes[i].name;
        attributes[name] = this.giftInfo.product.attributes[i].value;
        if (!attributes[name]) {
          this.$toast.error("Bạn chưa chọn " + this.giftInfo.product.attributes[i].label);
          return;
        }
      }

      let params = {
        ID: this.giftInfo.ID,
        receiver: this.giftInfo.receiver,
        phone: this.giftInfo.phone,
        address: this.giftInfo.address,
        note: this.giftInfo.note,
        attributes: JSON.stringify(attributes)
      }

      let query = `mutation($ID: ID!, $receiver: String!, $phone: String!, $address: String!, $attributes: String!, $note: String) {
        receivingGift(ID: $ID, receiver: $receiver, phone: $phone, address: $address, attributes: $attributes, note: $note)
      }`;

      ApiService.graphql(query, params)
          .then(({data}) => {
            if (data.data && data.data.receivingGift) {
              this.$emit("receivingGiftDone");
              window.$(".receivingGiftClose").click();
              this.$toast.success("Cảm ơn bạn đã " + this.action.toLowerCase() + " thông tin");
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    transform_attributes() {
      if (this.giftInfo && this.giftInfo.attributes && this.giftInfo.product && this.giftInfo.product.attributes && this.giftInfo.product.attributes.length) {
        try {
          let attributes = JSON.parse(this.giftInfo.attributes);
          for (var i = 0; i < this.giftInfo.product.attributes.length; i++) {
            let value = typeof attributes[this.giftInfo.product.attributes[i].name] !== 'undefined' ? attributes[this.giftInfo.product.attributes[i].name] : '';
            this.giftInfo.product.attributes[i].value = value;
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  },
  watch: {
    gift: {
      handler() {
        this.giftInfo = JSON.parse(JSON.stringify(this.gift));
        this.transform_attributes();
        if (this.giftInfo.status === 'opened') {
          this.action = 'Gửi';
        } else {
          this.action = 'Cập nhật';
        }
      },
      deep: true
    },
    
  }
}
</script>
