<template>
  <div id="receivedGiftModal" class="create-post" uk-modal>
    <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

      <div class="text-center py-3 border-b">
        <h3 class="text-lg font-semibold">Xác nhận đã nhận quà</h3>
        <button class="receivedGiftClose uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2"
                type="button" uk-close
                uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
      </div>

      <div class="px-5 py-2">
        Có phải bạn đã nhận được món quà <strong>"{{ gift && gift.product ? gift.product.post_title : '' }}"</strong> mà BCĐCNT đã gửi đến cho bạn?
      </div>

      <div class="flex items-center w-full justify-center border-t p-3">
        <a href="javascript:void(0)" v-on:click="receivedGift"
           class="bg-green-600 hover:bg-green-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
           Tôi đã nhận quà</a>
      </div>
    </div>
  </div>
</template>

<script>

import ApiService from "../../core/services/api.service";

export default {
  name: "ReceivedGiftModal",
  props: {
    gift: Object,
  },
  data() {
    return {
      
    }
  },
  methods: {
    receivedGift() {
      let query = `mutation($ID: ID!) {
        receivedGift(ID: $ID)
      }`;

      ApiService.graphql(query, {ID: this.gift.ID})
          .then(({data}) => {
            if (data.data && data.data.receivedGift) {
              this.$emit("receivedGiftDone");
              window.$(".receivedGiftClose").click();
              this.$toast.success("Cảm ơn bạn đã xác nhận thông tin");
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    }
  }
}
</script>
